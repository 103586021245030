html{
  --ch-grey-50: #f0efef;
  --ch-grey-100: #cfcece;
  --ch-grey-200: #b8b7b7;
  --ch-grey-300: #979696;
  --ch-grey-400: #838181;
  --ch-grey-500: #646262;
  --ch-grey-600: #5b5959;
  --ch-grey-700: #474646;
  --ch-grey-800: #373636;
  --ch-grey-900: #2a2828;

  --ch-blue-50: #f1f1fb;
  --ch-blue-100: #d3d4f2;
  --ch-blue-200: #bebfeb;
  --ch-blue-300: #a1a2e2;
  --ch-blue-400: #8e90dd;
  --ch-blue-500: #7274d4;
  --ch-blue-600: #686ac1;
  --ch-blue-700: #515297;
  --ch-blue-800: #3f4075;
  --ch-blue-900: #303159;

  --ch-yellow-50: #fff9f0;
  --ch-yellow-100: #ffedd0;
  --ch-yellow-200: #ffe4b9;
  --ch-yellow-300: #ffd899;
  --ch-yellow-400: #ffd185;
  --ch-yellow-500: #ffc567;
  --ch-yellow-600: #e8b35e;
  --ch-yellow-700: #b58c49;
  --ch-yellow-800: #8c6c39;
  --ch-yellow-900: #6b532b;

  --ch-green-50: #e8f9f5;
  --ch-green-100: #b7eddf;
  --ch-green-200: #94e4d0;
  --ch-green-300: #63d7bb;
  --ch-green-400: #45d0ad;
  --ch-green-500: #16c499;
  --ch-green-600: #14b28b;
  --ch-green-700: #108b6d;
  --ch-green-800: #0c6c54;
  --ch-green-900: #095240;

  --ch-red-50: #ffecec;
  --ch-red-100: #ffc3c3;
  --ch-red-200: #ffa5a5;
  --ch-red-300: #ff7c7c;
  --ch-red-400: #ff6363;
  --ch-red-500: #ff3c3c;
  --ch-red-600: #e83737;
  --ch-red-700: #b52b2b;
  --ch-red-800: #8c2121;
  --ch-red-900: #6b1919;
  
  --ch-orange-50: #ffede6;
  --ch-orange-100: #ffc8b0;
  --ch-orange-200: #ffae8a;
  --ch-orange-300: #ff8954;
  --ch-orange-400: #ff7233;
  --ch-orange-500: #ff4f00;
  --ch-orange-600: #e84800;
  --ch-orange-700: #b53800;
  --ch-orange-800: #8c2b00;
  --ch-orange-900: #6b2100;
}

html[data-theme="choirylight"] {
  --ch-primary: #7274D4;  //ch-blue-500
  --ch-primary-content: #FFFFFF;
  --ch-secondary: #FFC567;  //ch-yellow-500
  --ch-secondary-content: #2A2828;  //ch-grey-900
  --ch-neutral: #646262;  //ch-grey-500
  --ch-neutral-content: #FFFFFF;

  //PANELS
  --ch-info: #f0efef;  //grey-50
  --ch-info-content: #646262;  //grey-500
  --ch-success: #e8f9f5; //green-50
  --ch-success-content: #16c499;  //green-500
  --ch-warning: #ffede6;  //orange-50
  --ch-warning-content: #ff4f00;  //orange-500
  --ch-error: #ffecec;  //red-50
  --ch-error-content: #ff3c3c;  //red-500
  }