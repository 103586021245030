@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "themes";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;800&display=swap');
//mulish font
/* cyrillic-ext */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0gotcqA.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
/* cyrillic */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk2wotcqA.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
/* vietnamese */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0AotcqA.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0QotcqA.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk3wot.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0gotcqA.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
/* cyrillic */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk2wotcqA.woff2) format('woff2');
//   unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
/* vietnamese */
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0AotcqA.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
// }
/* latin-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk0QotcqA.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mulish/v12/1Ptvg83HX_SGhgqk3wot.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
//@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0');
//material symbols
@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v121/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOc7TOVpeRL2w5rwZu2rIelXxc.woff2) format('woff2');
}

.center-container {
  @apply px-4;
  @apply md:px-10;
  @apply lg:px-24;
  @apply mx-auto;
  max-width: 1440px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
  .font-roboto{
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .font-bely{
    font-family: 'bely-display', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
  .font-mulish{
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
  
  body{
    .font-mulish;
  }

  //DESKTOP
  @media (min-width: 1025px) {
    .font-display{
      .font-bely;
      font-weight: 400;
      font-size: 44px;
      line-height: 56px;
    }
    .font-headline{
      .font-bely;
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
    }
    .font-title{
      .font-mulish;
      font-weight: 800;
      font-size: 22px;
      line-height: 32px;
    }
    .font-body{
      .font-mulish;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .font-boldbody{
      .font-mulish;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
    .font-label{
      .font-mulish;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
    .font-button{
      .font-mulish;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
    }
    .font-button-small{
      .font-mulish;
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
    }
    .ch-btn {
      .font-button;
    }
    .ch-btn-small {
      .font-button-small;
    }
    label{ .font-label;}
    h1{ .font-display; padding-bottom: 72px;}
    h2{ .font-display; padding-bottom: 48px}
    h3{ .font-headline; padding-bottom: 32px;}
    h4{ .font-headline; padding-bottom: 16px;}
    h5{ .font-title; padding-bottom: 8px;}
    h6{ .font-title; padding-bottom: 4px;}
    .home-newsletter-input::placeholder{
      @apply text-white; 
      .font-label; 
    }
  }
  //TABLET
  @media (min-width: 769px) {
    .font-display{
      .font-bely;
      font-weight: 400;
      font-size: 40px;
      line-height: 56px;
    }
    .font-headline{
      .font-bely;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
    }
    .font-title{
      .font-mulish;
      font-weight: 800;
      font-size: 20px;
      line-height: 32px;
    }
    .font-body{
      .font-mulish;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    .font-boldbody{
      .font-mulish;
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
    }
    .font-label{
      .font-mulish;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
    }
    .font-button{
      .font-mulish;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
    }
    .font-button-small{
      .font-mulish;
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
    }
    .ch-btn {
      .font-button;
    }
    .ch-btn-small {
      .font-button-small;
    }
    label{ .font-label;}
    h1{ .font-display; padding-bottom: 72px;}
    h2{ .font-display; padding-bottom: 48px}
    h3{ .font-headline; padding-bottom: 32px;}
    h4{ .font-headline; padding-bottom: 16px;}
    h5{ .font-title; padding-bottom: 8px;}
    h6{ .font-title; padding-bottom: 4px;}
    .home-newsletter-input::placeholder{@apply text-white; .font-label; }
  }
  //MOBILE
  @media (max-width: 768px) {
    .font-display{
      .font-bely;
      font-weight: 400;
      font-size: 36px;
      line-height: 52px;
    }
    .font-headline{
      .font-bely;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }
    .font-title{
      .font-mulish;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
    }
    .font-body{
      .font-mulish;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .font-boldbody{
      .font-mulish;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
    }
    .font-label{
      .font-mulish;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
    .font-button{
      .font-mulish;
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
    }
    .font-button-small{
      .font-mulish;
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
    }
    .ch-btn {
      .font-button;
    }
    .ch-btn-small {
      .font-button-small;
    }
    label{ .font-label;}
    h1{ .font-display; padding-bottom: 72px;}
    h2{ .font-display; padding-bottom: 48px}
    h3{ .font-headline; padding-bottom: 32px;}
    h4{ .font-headline; padding-bottom: 16px;}
    h5{ .font-title; padding-bottom: 8px;}
    h6{ .font-title; padding-bottom: 4px;}
    .home-newsletter-input::placeholder{@apply text-white; .font-label; }
  }

  .main-content{
    @apply mx-auto;
    @apply px-4;
    @apply pt-6;
    @apply md:pt-28;
    @apply md:px-10;
    @apply lg:px-24;
    @apply pb-14;
    @apply md:pb-20;
    @apply lg:pb-32;
  }


  /****************BGBLUR*************************/
  .ch-bg-blur{
    border-radius: 989px;
    opacity: 0.7;
    @apply bg-ch-blue-50;
    filter: blur(300px);
  }
  /****************BUTTONS************************/
  .ch-btn {
    border-radius: 100px;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 14px 40px 14px 40px;
    cursor: pointer;
  }

  .ch-btn-small{
    border-radius: 100px;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
  }

  //BTN FILLED
  .ch-btn-filled{  }
  .ch-btn-filled:hover{
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30); 
  }
  .ch-btn-filled.ch-btn-pri{
    @apply bg-primary;
    @apply text-white;
  }
  .ch-btn-filled.ch-btn-sec{
    @apply bg-secondary;
    @apply text-ch-grey-900;
  }
  
  //BTN OUTLINE
  .ch-btn-outline:disabled {
    @apply !bg-ch-grey-50;
    @apply !text-ch-grey-600;
  }
  .ch-btn-outline{
    @apply border-solid;
    @apply border;
  }
  .ch-btn-outline.ch-btn-sec{
    @apply border-secondary;
    @apply text-ch-grey-900;
  }
  .ch-btn-outline.ch-btn-pri{
    @apply border-primary;
    @apply text-primary;
  }
  .ch-btn-pri.ch-btn-outline:hover,.ch-btn-pri.ch-btn-outline:focus,.ch-btn-pri.ch-btn-outline:active{
    @apply bg-ch-blue-50;
  }
  .ch-btn-sec.ch-btn-outline:hover,.ch-btn-sec.ch-btn-outline:focus,.ch-btn-sec.ch-btn-outline:active{
    @apply bg-ch-yellow-50;
  }
  .ch-btn-outline:disabled {
    @apply bg-ch-grey-50;
    @apply !text-ch-grey-600;
    @apply !border-ch-grey-600;
  }
  //BTN TEXT (TODO COLORS)
  .ch-btn-text{
    @apply text-ch-grey-900;
    padding: 14px 24px !important; 
  }
  .ch-btn-text:hover{
    @apply bg-[#FFFBEF];
  }
  .ch-btn-text:focus,.ch-btn-text:active{
    @apply bg-ch-yellow-50;
  }
  .ch-btn-text:disabled{
    @apply !text-ch-grey-600;
  }
  //BTN ELEVATED (TODO COLORS)
  .ch-btn-elevated{
    @apply text-ch-grey-900;
    @apply bg-[#FFFBEF];
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  }
  .ch-btn-elevated:hovered{
    @apply !bg-ch-yellow-50;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important; 
  }
  .ch-btn-elevated:focus,.ch-btn-elevated:active{
    @apply !bg-ch-yellow-100;
  }
  .ch-btn-elevated:disabled{
    @apply !bg-ch-grey-50;
    @apply !text-ch-grey-600;
    box-shadow: none;
  }
  //BTN FILLED TONAL (TODO COLORS)
  .ch-btn-filled-tonal{
    @apply text-ch-grey-600;
    @apply bg-ch-yellow-50;
  }
  .ch-btn-filled-tonal:hover{
    @apply !bg-ch-grey-50;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30); 
  }
  .ch-btn-filled-tonal:focus,.ch-btn-filled-tonal:active{
    @apply !bg-ch-grey-50;
  }
  .ch-btn-filled-tonal:disabled{
    opacity: 0.38;
  }

  .ch-card {
    @apply bg-white;
  }

  /****************INPUTS*************************/
  .home-newsletter-input{
    width: 294px;
    @apply border-none;
    @apply outline-none;
    background: none;
    border-bottom:1px solid;
    @apply border-white;
  }

  .choiry-input{
    //@apply w-60;
    //@apply md:w-[400px];
    @apply border-none;
    @apply outline-none;
    background: none;
    border-bottom:1px solid var(--ch-grey-100);
    @apply text-ch-grey-900;
  }

  .choiry-input::placeholder{
    @apply text-ch-grey-500;
  }

  .choiry-input-feedback{
    @apply rounded-full;
    @apply bg-neutral-content;
    @apply text-neutral;
    @apply z-30;
    @apply absolute;
    @apply left-0;
    @apply top-12;
    //todo padding
  }

  .choiry-menu-item{
    @apply p-2;
    @apply rounded-md;
    @apply cursor-pointer;
    @apply hover:bg-primary;
    @apply hover:text-white;
  }

  .icon {
    .material-symbols-rounded;
  }

  .icon-16 {.icon; font-size: 16px;}
  .icon-20 {.icon; font-size: 20px;}
  .icon-24 {.icon; font-size: 24px;}
  .icon-32 {.icon; font-size: 32px;}
  .icon-40 {.icon; font-size: 40px;}
  .icon-48 {.icon; font-size: 48px;}
  .icon-56 {.icon; font-size: 56px;}
  .icon-64 {.icon; font-size: 64px;}
  

  .icon-btn {
    @apply cursor-pointer;
    @apply hover:opacity-30;
  }

  .icon-btn-toggled {
    @apply text-secondary;
  }

  .icon-btn-disabled {
    @apply cursor-default;
    @apply hover:opacity-100;
  }

  .icon-social-facebook {
    mask: url(/assets/images/plain_svg/icon-facebook.svg);
    display: inline-block;
    mask-size: cover;
  }

  .icon-social-x {
    mask: url(/assets/images/plain_svg/icon-x.svg);
    display: inline-block;
    mask-size: cover;
  }

  .icon-social-instagram {
    mask: url(/assets/images/plain_svg/icon-instagram.svg);
    display: inline-block;
    mask-size: cover;
  }

  .icon-social-youtube {
    mask: url(/assets/images/plain_svg/icon-youtube.svg);
    display: inline-block;
    mask-size: cover;
  }

  .icon-social-linkedin {
    mask: url(/assets/images/plain_svg/icon-linkedin.svg);
    display: inline-block;
    mask-size: cover;
  }

  .icon-choiry-sygnet {
    mask: url(/assets/images/choiry/choiry-sygnet.svg);
    display: inline-block;
    mask-size: cover;
  }

//FIX MODAL
.modal-backdrop.fade
{opacity: 0.5;}
.modal-open .modal
{opacity: 1; visibility: inherit; pointer-events: auto;}
.modal-dialog {
  background-color: white;
  border-radius: 20px;
}

//LEGACY!!!
.ch-list{
  @apply px-2;
  @apply py-4;
  @apply rounded-lg;
  @apply bg-ch-blue-100;
}

.ch-list-item{
  @apply w-full;
  @apply rounded-lg;
  @apply hover:bg-ch-blue-200;
  @apply items-center;
}

.ch-list-item-active{
  @apply text-primary;
}


//RADIO V
.choiry-v-radio{
  @apply bg-ch-blue-100;
  @apply text-secondary-content;
  @apply hover:bg-ch-blue-200;
  @apply inline-block;
  @apply cursor-pointer;
  @apply p-2;
}

.choiry-v-radio-active{
  @apply bg-primary;
  @apply text-primary-content;
  @apply inline-block;
  @apply p-2;
}

.choiry-v-radio-l{
  @apply rounded-l-xl;
}

.choiry-v-radio-r{
  @apply rounded-r-xl;
}

//SLIDER
.switch-normal {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-small {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch-normal input {display:none;}
.switch-small input {display:none;}

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @apply bg-ch-blue-100;
    -webkit-transition: .4s;
    transition: .4s;
    @apply rounded-full;
    //border-radius: 34px;
  }
  
  .slider-normal:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    @apply rounded-full;
    //border-radius: 34px;
  }

  .slider-small:before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    @apply rounded-full;
    //border-radius: 34px;
  }

  input:checked + .slider {
    @apply bg-primary;
  }
  
  input:focus + .slider {
    @apply shadow-sm;
  }
  
  input:checked + .slider-normal:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  input:checked + .slider-small:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }